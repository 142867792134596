import React, { useRef, useState, useEffect } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import SlidingBox from "./SlidingBoxes/SlidingBoxes";
import Modal from "./Modal/Modal";
import { projectsData } from "./projectsData";
import "./Projects.css";

interface PopupContent {
  title: string;
  learnMoreText: string;
  imageUrls: string[];
  logos?: string[];
}

const Projects: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPopupContent, setSelectedPopupContent] = useState<
    PopupContent[] | null
  >(null);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const marqueeRef = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(
    marqueeRef as React.MutableRefObject<HTMLDivElement>
  );
  const animationRef = useRef<number>();
  const scrollSpeed = 0.5; // Adjust this value to control scroll speed

  useEffect(() => {
    const autoScroll = () => {
      if (!isUserInteracting && marqueeRef.current) {
        const container = marqueeRef.current;
        container.scrollLeft += scrollSpeed;

        // Calculate the total width of one set of projects
        const singleSetWidth = container.scrollWidth / 3;

        // When we reach the end of the first set, jump back to the start
        if (container.scrollLeft >= singleSetWidth) {
          container.scrollLeft = 0;
        }
      }
      animationRef.current = requestAnimationFrame(autoScroll);
    };

    animationRef.current = requestAnimationFrame(autoScroll);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isUserInteracting]);

  const handleInteractionStart = () => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    setIsUserInteracting(true);
    setIsDragging(true);
  };

  const handleInteractionEnd = () => {
    setIsDragging(false);
    // Delay before resuming auto-scroll
    setTimeout(() => {
      setIsUserInteracting(false);
    }, 1000); // Longer delay before resuming
  };

  const openModal = (projects: PopupContent[]) => {
    setSelectedPopupContent(projects);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPopupContent(null);
  };

  return (
    <section id="section4" className="projects">
      <div className="projects-title">
        Powering Innovation Across Industries
      </div>
      <div
        className="marquee-container"
        {...events}
        ref={marqueeRef}
        style={{
          overflowX: "auto",
          cursor: isDragging ? "grabbing" : "grab",
          display: "flex",
          width: "100%",
          WebkitOverflowScrolling: "touch",
        }}
        onTouchStart={handleInteractionStart}
        onTouchEnd={handleInteractionEnd}
        onMouseEnter={handleInteractionStart}
        onMouseLeave={handleInteractionEnd}
      >
        {[0, 1, 2].map((setIndex) => (
          <div
            key={setIndex}
            style={{
              display: "flex",
              willChange: "transform",
              transform: "translate3d(0,0,0)",
            }}
          >
            {projectsData.map((project, index) => (
              <div
                key={`${setIndex}-${index}`}
                className="project-container"
                style={{ minWidth: "350px" }}
              >
                <SlidingBox
                  project={project}
                  openModal={() =>
                    openModal(project.popupContents || [project.popupContent!])
                  }
                />
              </div>
            ))}
          </div>
        ))}
      </div>

      {selectedPopupContent && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          popupContents={selectedPopupContent}
        />
      )}
    </section>
  );
};

export default Projects;
