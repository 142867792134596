import React from "react";
import "./SlidingBoxes.css";

interface PopupContent {
  title: string;
  learnMoreText: string;
  imageUrls: string[];
  logos?: string[];
}

interface Project {
  title: string;
  description: string;
  imageUrl: string;
  logos?: string[];
  popupContent?: PopupContent; // Single popup content (optional)
  popupContents?: PopupContent[]; // Multiple popup contents (optional)
}

interface SlidingBoxProps {
  project: Project;
  openModal: (projects: PopupContent[]) => void;
}

const SlidingBox: React.FC<SlidingBoxProps> = ({ project, openModal }) => {
  return (
    <div
      className="sliding-box"
      style={{ backgroundImage: `url(${project.imageUrl})` }}
      onClick={() =>
        openModal(project.popupContents || [project.popupContent!])
      }
    >
      <div className="sliding-box-overlay">
        <div className="sliding-box-content">
          <div className="sliding-box-header">
            <h2 className="sliding-box-title">{project.title}</h2>
          </div>
          <p className="sliding-box-description">{project.description}</p>
          <button
            className="learn-more-button"
            onClick={() =>
              openModal(project.popupContents || [project.popupContent!])
            }
          >
            More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlidingBox;
