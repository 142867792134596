import React from "react";
import Marquee from "react-fast-marquee";
import "./Logos.css";

import Clew from "../../assets/images/clew.svg";
import Cybring from "../../assets/images/cybring.svg";
import IAI_ELTA from "../../assets/images/IAI_ELTA.svg";
import IAI_logo from "../../assets/images/IAI_logo.svg";
import Icarbonx from "../../assets/images/icarbonx.svg";
import Leventech from "../../assets/images/leventech.svg";
import Hot from "../../assets/images/logo-hot.svg";
import ODM from "../../assets/images/ODM_logo.svg";
import Pi from "../../assets/images/pi.svg";
import Rafael from "../../assets/images/rafael.svg";
import Skinapp from "../../assets/images/Skinapp_logo_black.svg";
import Splitit from "../../assets/images/splitit.svg";
import Zsmile from "../../assets/images/ZsmileLogo.svg";
import ActiveAging from "../../assets/images/Active_Aging_Logo_White.svg";

const Logos = () => {
  return (
    <div className="logos">
      <Marquee speed={60} gradient={false}>
        <img src={Clew} alt="Clew" className="logo-image" />
        <img src={Cybring} alt="Cybring" className="logo-image" />
        <img src={IAI_logo} alt="IAI" className="logo-image" />
        <img src={IAI_ELTA} alt="IAI ELTA" className="logo-image" />
        <img src={Icarbonx} alt="Icarbonx" className="logo-image" />
        <img src={Leventech} alt="Icarbonx" className="logo-image" />
        <img src={Hot} alt="Hot" className="logo-image" />
        <img src={ODM} alt="ODM" className="logo-image" />
        <img src={Pi} alt="Pi" className="logo-image" />
        <img src={Rafael} alt="Rafael" className="logo-image" />
        <img src={Skinapp} alt="Skinapp" className="logo-image" />
        <img src={Splitit} alt="Splitit" className="logo-image" />
        <img src={Zsmile} alt="Zsmile" className="logo-image" />
        <img src={ActiveAging} alt="ActiveAging" className="logo-image" />
      </Marquee>
    </div>
  );
};

export default Logos;
