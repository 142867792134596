import React from "react";
import "./TeamCard.css";

interface TeamCardProps {
  imageSrc: string;
  name: string;
  jobTitle: string;
}

const TeamCard: React.FC<TeamCardProps> = ({ imageSrc, name, jobTitle }) => {
  const formattedJobTitle = jobTitle.split(",").map((title, index) => (
    <React.Fragment key={index}>
      {title.trim()}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="team-card">
      <img className="team-card_image" src={imageSrc} alt={`${name}'s photo`} />
      <h3 className="team-card_name">{name}</h3>
      <p className="team-card_job-title">{formattedJobTitle}</p>
    </div>
  );
};

export default TeamCard;
