import React, { useState } from "react";
import "./Modal.css";

interface PopupContent {
  title: string;
  learnMoreText: string;
  imageUrls: string[];
  logos?: string[];
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  popupContents: PopupContent[];
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, popupContents }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  const handleVideoClick = (url: string) => {
    setSelectedVideo(url);
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };

  const handleCloseVideoModal = () => {
    setSelectedVideo(null);
  };

  const isGoogleDriveVideo = (url: string) => {
    return url.includes("drive.google.com/file/d/");
  };

  const getEmbedUrl = (url: string) => {
    const fileId = url.match(/\/d\/(.+?)\//)?.[1];
    return `https://drive.google.com/file/d/${fileId}/preview?usp=embed_facebook`; // Add parameters
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <span className="modal-close" onClick={onClose}>
            &times;
          </span>
          {popupContents.map((content, index) => (
            <div key={index} className="project-section">
              <div className="modal-title">{content.title}</div>
              <div className="modal-logos">
                {content.logos &&
                  content.logos.map((logo, logoIndex) => (
                    <img
                      key={logoIndex}
                      src={logo}
                      alt={`Logo ${logoIndex + 1}`}
                      className="modal-logo"
                    />
                  ))}
              </div>
              <div className="modal-text">{content.learnMoreText}</div>
              <div className="modal-images">
                {content.imageUrls.map((url, imageIndex) =>
                  isGoogleDriveVideo(url) ? (
                    <div
                      key={imageIndex}
                      className="modal-video-preview"
                      onClick={() => handleVideoClick(getEmbedUrl(url))}
                    >
                      <iframe
                        src={getEmbedUrl(url)}
                        title={`video-${imageIndex}`}
                        allowFullScreen
                      />
                      <div className="play-button" />
                    </div>
                  ) : (
                    <img
                      key={imageIndex}
                      src={url}
                      alt={`popup-image-${imageIndex}`}
                      onClick={() => handleImageClick(url)}
                    />
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="image-modal-overlay" onClick={handleCloseImageModal}>
          <div
            className="image-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="image-modal-close" onClick={handleCloseImageModal}>
              &times;
            </span>
            <img src={selectedImage} alt="Selected" />
          </div>
        </div>
      )}

      {selectedVideo && (
        <div className="image-modal-overlay" onClick={handleCloseVideoModal}>
          <div
            className="modal-video-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="image-modal-close" onClick={handleCloseVideoModal}>
              &times;
            </span>
            <iframe
              src={selectedVideo}
              title="Video Modal"
              allow="autoplay"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
