import React from "react";
import "./MeetTheTeam.css";
import TeamCard from "./TeamCard/TeamCard";

import defaultUser from "../../assets/images/default-image-user.png";
import shachar from "../../assets/images/shachar_cohen_image.png";
import yossi from "../../assets/images/yossi_avni_image.png";
import danor from "../../assets/images/dan_image.png";

const MeetTheTeam: React.FC = () => {
  const teamMembers = [
    {
      imageSrc: yossi,
      name: "Yossi Avni",
      jobTitle: "Founder and CTO, Cyber Security Expert, AI Expert",
    },
    {
      imageSrc: shachar,
      name: "Shachar Cohen",
      jobTitle: "R&D Manager & Partner",
    },
    {
      imageSrc: defaultUser,
      name: "Eytan H.S.",
      jobTitle: "AI Expert, AI Researcher",
    },
    {
      imageSrc: defaultUser,
      name: "Guy B.",
      jobTitle: "Projects Manager, Data Eng. Developer",
    },
    {
      imageSrc: defaultUser,
      name: "Meitar T.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: defaultUser,
      name: "Scott G.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: danor,
      name: "Danor S.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: defaultUser,
      name: "Dean T.",
      jobTitle: "Senior Data Scientist, Researcher",
    },
    {
      imageSrc: defaultUser,
      name: "Nataly Avni",
      jobTitle: "Founder and CEO",
    },
  ];

  return (
    <section className="meet-the-team">
      <h2 className="meet-the-team__title">Meet Our Team Leaders</h2>
      <div className="meet-the-team__grid">
        {teamMembers.map((member, index) => (
          <TeamCard
            key={index}
            imageSrc={member.imageSrc}
            name={member.name}
            jobTitle={member.jobTitle}
          />
        ))}
      </div>
    </section>
  );
};

export default MeetTheTeam;
