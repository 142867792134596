import React from "react";
import AnimationContainer from "../AnimationContainer/AnimationContainer";
import "./Services.css";
import aiIcon from "../../assets/icons/ai.svg";
import expertIcon from "../../assets/icons/expert.svg";
import securityIcon from "../../assets/icons/security.svg";
import startupIcon from "../../assets/icons/startup.svg";
import prototypeIcon from "../../assets/icons/prototype.svg";
import partnershipIcon from "../../assets/icons/partnership.svg";

const servicesData = [
  {
    title: "AI-Powered Innovation",
    text: "Cutting-edge AI solutions for complex challenges",
    icon: aiIcon,
  },
  {
    title: "Expert Development",
    text: "Seasoned developers crafting robust, scalable software",
    icon: expertIcon,
  },
  {
    title: "Security-First Approach",
    text: "Robust protection for your data and innovations",
    icon: securityIcon,
  },
  {
    title: "Startup Acceleration",
    text: "Propelling startups from concept to market leaders",
    icon: startupIcon,
  },
  {
    title: "Rapid Prototyping",
    text: "Swift idea validation and market-ready prototypes",
    icon: prototypeIcon,
  },
  {
    title: "Long-Term Partnership",
    text: "Committed to your success beyond project completion",
    icon: partnershipIcon,
  },
];

const Services = () => {
  return (
    <section id="section3" className="services">
      {/* <div className="soda-advantage-container"> */}
      <div className="section-title">
        <div className="section-text">The Soda Advantage</div>
        <div className="services-grid">
          {servicesData.map((service, index) => (
            <div key={index} className="service-box">
              <div className="service-box-bar"></div>
              <div className="service-box-title">{service.title}</div>
              <div className="service-box-content">
                <img
                  src={service.icon}
                  alt={`${service.title} icon`}
                  className="service-box-icon"
                />
                <div className="service-box-text">{service.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* </div> */}
    </section>
  );
};

export default Services;
