export const servicesData = [
  {
    title: "AI Model Development",
    description:
      "AI model development and integration services customized for industry-specific needs with advanced analytics capabilities.",
  },
  {
    title: "Software R&D",
    description:
      "We develop custom AI models tailored to industries that integrate with other applications for advanced analytics and decision-making.",
  },
  {
    title: "Cybersecurity Solutions",
    description:
      "Design and implementation of AI-powered cybersecurity systems featuring threat detection, encryption, and real-time monitoring tools.",
  },
  {
    title: "Autonomous Systems",
    description:
      "AI technologies for autonomous systems using advanced sensors and algorithms to improve safety and efficiency in automated operations.",
  },
  {
    title: "Medical Technology",
    description:
      "We develop AI-powered medical technology solutions for diagnostics, smart devices, and imaging to enhance patient care and monitoring.",
  },
  {
    title: "Machine Vision",
    description:
      "We develop advanced machine vision systems for automated inspection, tracking, and quality control across multiple industries.",
  },
  {
    title: "IoT Integration",
    description:
      "We integrate IoT systems with advanced computer vision and real-time analytics for improved operations across business and military applications.",
  },
  {
    title: "Systems Architecture",
    description:
      "We design and optimize integrated IT systems with expertise in cloud infrastructure and emerging technologies for maximum efficiency.",
  },
  {
    title: "UX/UI Design",
    description:
      "We create user-centric UX/UI designs that prioritize usability and align with business objectives and brand identity.",
  },
  {
    title: "Data Tagging for AI Model Creation",
    description:
      "We provide specialized data annotation and tagging services to enhance AI model training with high-quality labeled datasets.",
  },
  {
    title: "Defense Technologies",
    description:
      "We develop AI-powered defense technologies for radar classification and non-GPS navigation to enhance operational capabilities in mission-critical environments.",
  },
  {
    title: "Consulting & Advisory",
    description:
      "We provide strategic IP consulting and technology planning services, helping companies develop and manage their patent portfolios and tech strategies.",
  },
];
