import React, { useEffect, useRef } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Expertise from "./components/Expertise/Expertise"; // Import the new section
import Projects from "./components/Projects/Projects";
import Services from "./components/Services/Services";
import MeetTheTeam from "./components/MeetTheTeam/MeetTheTeam";
import Contact from "./components/Contact/Contact";
import AnimationContainer from "./components/AnimationContainer/AnimationContainer";
import { ScrollProvider, useScroll } from "./context/ScrollContext";

const AppContent = () => {
  const appRef = useRef<HTMLDivElement>(null);
  const prevScrollTopRef = useRef<number>(0); // Initialize the reference with 0
  const { setScrollPosition } = useScroll();

  const handleScroll = () => {
    const currentScrollTop = appRef.current?.scrollTop || 0;

    setScrollPosition(currentScrollTop);

    prevScrollTopRef.current = currentScrollTop; // Update the reference with the current scroll position
  };

  useEffect(() => {
    const div = appRef.current;
    div?.addEventListener("scroll", handleScroll);

    return () => {
      div?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App" ref={appRef}>
      <AnimationContainer />
      <Navbar />
      <Header />
      <Projects />
      <Expertise />
      <Services />
      <About />
      <MeetTheTeam />
      <Contact />
    </div>
  );
};

const App = () => (
  <ScrollProvider>
    <AppContent />
  </ScrollProvider>
);

export default App;
