import React from "react";
import "./Expertise.css";
import { servicesData } from "./servicesData";
import {
  FaCogs,
  FaShieldAlt,
  FaLaptopCode,
  FaRobot,
  FaStethoscope,
  FaNetworkWired,
  FaSitemap, // For Systems Architecture
  FaObjectGroup, // For UX/UI Design
  FaTags, // For Data Tagging
  FaCamera, // For Machine Vision
  FaLock,
  FaLightbulb,
} from "react-icons/fa";

const iconMapping: Record<string, JSX.Element> = {
  "AI Model Development": <FaCogs />,
  "Software R&D": <FaLaptopCode />,
  "Cybersecurity Solutions": <FaShieldAlt />,
  "Autonomous Systems": <FaRobot />,
  "Medical Technology": <FaStethoscope />,
  "Machine Vision": <FaCamera />, // Updated icon
  "IoT Integration": <FaNetworkWired />,
  "Systems Architecture": <FaSitemap />, // Updated Expertise
  "UX/UI Design": <FaObjectGroup />, // New Expertise
  "Data Tagging for AI Model Creation": <FaTags />, // Updated icon
  "Defense Technologies": <FaLock />,
  "Consulting & Advisory": <FaLightbulb />,
};

const Expertise = () => {
  return (
    <section id="services" className="expertise">
      <h2 className="expertise-title">Our Expertise</h2>
      <div className="expertise-services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-item">
            <div className="service-icon">{iconMapping[service.title]}</div>
            <div className="service-title">{service.title}</div>
            <div className="tooltip">{service.description}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Expertise;
